export const logoMapper = {
    'ABAP':require('../images/icons/languages/Abap.svg'),
    'TeX':require('../images/icons/languages/TeX.svg'),
    'Ada':require('../images/icons/languages/Ada.png'),
    'C':require('../images/icons/languages/C.svg'),
    'C++':require('../images/icons/languages/C++.svg'),
    'Jupyter Notebook':require('../images/icons/languages/Jupyter_Notebook.svg'),
    'C#':require('../images/icons/languages/C#.svg'),
    'COBOL':require('../images/icons/languages/Cobol.svg'),
    'Dart':require('../images/icons/languages/Dart.png'),
    'Delphi':require('../images/icons/languages/Delphi.svg'),
    'Go':require('../images/icons/languages/Go.svg'),
    'Groovy':require('../images/icons/languages/Groovy.svg'),
    'Haskell':require('../images/icons/languages/Haskell.svg'),
    'HTML':require('../images/icons/languages/HTML.png'),
    'Julia':require('../images/icons/languages/Julia.png'),
    'Kotlin':require('../images/icons/languages/Kotlin.svg'),
    'Java':require('../images/icons/languages/Java.svg'),
    'JavaScript':require('../images/icons/languages/Javascript.svg'),
    'Lua':require('../images/icons/languages/Lua.svg'),
    'MATLAB':require('../images/icons/languages/Matlab.svg'),
    'Objective-C':require('../images/icons/languages/Objective-C.svg'),
    'Perl':require('../images/icons/languages/Perl.svg'),
    'PHP':require('../images/icons/languages/PHP.svg'),
    'Python':require('../images/icons/languages/Python.svg'),
    'R':require('../images/icons/languages/R.svg'),
    'Ruby':require('../images/icons/languages/Ruby.svg'),
    'Rust':require('../images/icons/languages/Rust.svg'),
    'Scala':require('../images/icons/languages/Scala.png'),
    'Swift':require('../images/icons/languages/Swift.svg'),
    'TypeScript':require('../images/icons/languages/TypeScript.svg'),
    'VBA':require('../images/icons/languages/VBA.svg'),
    'Visual Basic':require('../images/icons/languages/Visual_Basic.svg'),
    'Vue':require('../images/icons/languages/Vue.svg'),
    'TSQL':require('../images/icons/languages/TSQL.svg'),
    'Stan':require('../images/icons/languages/Stan.png'), 
    'CSS':require('../images/icons/languages/css.svg'),
    'OpenSCAD':require('../images/icons/languages/Openscad.svg'),
    'Shell':require('../images/icons/languages/Shell.png'),
    'SCSS':require('../images/icons/languages/SCSS.png'),
    'Mathematica':require('../images/icons/languages/Mathematica.png'),
    'Clojure':require('../images/icons/languages/Clojure.svg'),
    'Common Lisp':require('../images/icons/languages/Common_Lisp.png'),
    'F#':require('../images/icons/languages/f#.png'),
    'Elixir':require('../images/icons/languages/Elixir.png'),
    'Svelte':require('../images/icons/languages/Svelte.svg'),
    'GLSL':require('../images/icons/languages/GLSL.png'),
    'NetLogo':require('../images/icons/languages/NetLogo.png'),
    'PureScript':require('../images/icons/languages/PureScript.jpg'),
    'Nix':require('../images/icons/languages/Nix.png'),
    /*
    format for future ease
    'Lang':require('../images/icons/languages/Lang.svg'),
    */
  };
